.thumbnail {
  --size: 100;
  position: relative;
}
.thumbnailInModal {
  height: 30vh;
}

.shelfBorderContainer {
  display: flex;
  & > img {
    width: 100%;
  }
}

.shelfMiddleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  // image width: 1064px
  // image left space: 42px
  // image right space: 40px
  padding-left: calc(42px / 1064px * 100%);
  padding-right: calc(40px / 1064px * 100%);
  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
}

.foodInShelf {
  width: min(50%, 160px);
}

.imageContainer {
  display: flex;
  width: 120px;
  height: 120px;
  & > img {
    margin: auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.coverImage {
  opacity: 0;
}
.coverImageContainer {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
