.gameMapContainer {
  --cell-size: calc(33 * var(--vw));
}

.gameMap {
  margin: auto;
  background-position: center;
  background-size: cover;
}

.cell {
  width: var(--cell-size);
  height: var(--cell-size);
}

.player {
  position: absolute;
  width: var(--cell-size);
  height: var(--cell-size);
  --space-size: 1rem;
  padding: var(--space-size);
  ion-avatar {
    width: 75%;
    height: 75%;
    margin: auto;
    padding-top: calc(var(--space-size) / 2);
  }
  ion-text {
    position: absolute;
    top: 75%;
    bottom: 0;
    left: var(--space-size);
    right: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    color: var(--ion-color-secondary);
    font-weight: bold;
    // background-color: rgba(255, 0, 0, 0.5);
  }
}
.playerInner {
  width: calc(var(--cell-size) - var(--space-size));
  height: calc(var(--cell-size) - var(--space-size));
  background-color: #921d3288;
  border-radius: 50%;
}

.memberList {
  display: inline-flex;
}

.roomMember,
.player {
  text-align: center;
}
.roomMember ion-avatar {
  margin: auto;
}

.dice {
  float: right;
  margin: auto;
}
