.addPhoto {
  border: 0.9em dashed var(--ion-color-primary);
  height: 16em;
}

.addIcon {
  width: 8em;
  height: 8em;
}

.categoryImage {
  max-width: 30vw;
}
