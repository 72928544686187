.pageTitle {
  font-size: medium;
}

.roomList {
  display: flex;
  flex-wrap: wrap;
}

.roomMember {
  text-align: center;
  margin: 0 0.5em;
}
