.rankTable {
  border-collapse: collapse;
  margin: auto;
}
.rankTable th,
.rankTable td
 {
  padding: 1rem;
  border: 1px solid black;
}
