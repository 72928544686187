@mixin image-bg {
  background-size: cover;
  background-position: center;
}
.starBar {
  display: flex;
  justify-content: space-between;
  @include image-bg;
}
.star {
  @include image-bg;
  width: 5rem;
  height: 5rem;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.starFinish {
  @extend .star;
}
.starNotFinish {
  @extend .star;
}

.chatPointerContainer {
  padding: 0 0.1rem;
  display: flex;
  justify-content: space-between;
}

.chatPointer {
  margin-inline-start: 12%;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid var(--ion-color-secondary);
}

.chatBox {
  background-color: var(--ion-color-secondary);
  border-radius: 1.25rem;
  padding: 0.5rem 1rem;
  text-align: justify;
  margin-bottom: 1rem;
}
