.hintContainer {
  background-color: var(--ion-color-secondary);
  display: flex;
  padding: 0.5rem;
  padding-left: 0;
}
.tipsIcon {
  height: 2.5rem;
  margin: 0.5rem;
}
