h1:not(.no-primary),
h2:not(.no-primary),
h3:not(.no-primary),
h4:not(.no-primary) {
  color: var(--ion-color-primary);
}

.p-2 {
  padding: 0.5em;
}

.pt-2 {
  padding-top: 0.5em;
}

.pb-1 {
  padding-bottom: 0.25em;
}

.px-3 {
  padding-left: 1em;
  padding-right: 1em;
}

.ms-2 {
  margin-inline-start: 0.5em;
}

.mt-2 {
  margin-top: 0.5em;
}

.mt-3 {
  margin-top: 1em;
}

.mb-3 {
  margin-bottom: 1em;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

iframe[style] {
  display: none;
}

.swal2-container.swal2-backdrop-show {
  background: rgba(235, 210, 215, 0.4);
}

.flex-grow {
  flex-grow: 1;
}
