.avatar {
  --size: 80px;
  width: var(--size);
  height: var(--size);
}

.starTable {
}
.starTable td {
  padding: 0 0.5rem;
}
