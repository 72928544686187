@import "../global/helpers.module.scss";

.avatar {
  --size: 80px;
  width: var(--size);
  height: var(--size);
}

.nickname {
  @extend .Center;
  margin: 10px;
  color: #921d32;
  border-bottom: 1px dotted grey;
  font-size: 1.5em;
}

.badgeGroup {
  text-align: center;
  margin: 0.25rem 0;
}

.badgeContainer {
  --unit: 4;
  width: calc((var((--vw)) * 100 - 32px) / var(--unit));
  height: calc((var((--vw)) * 100 - 32px) / var(--unit));
  display: inline-flex;
  justify-content: center;
  margin: 0 0.25rem;
}
.bigBadgeContainer {
  --unit: 2;
}
.badge {
  --size: 200px;
  max-width: var(--size);
  max-height: var(--size);
}
.badgeLocked {
  opacity: 0.3;
}
.bigBadge {
  --size: 400px;
}

.badgeText {
}

.certContainer {
  background-color: #ffba24;
  padding: 1.5rem;
}
.certInnerContainer {
  background-color: white;
  border-radius: 1.5rem;
  padding: 0.5rem;
}
.certIconContainer {
  padding: 0 2rem;
}
.certLogoContainer {
  height: 8rem;
}
.certLogo {
  height: 10rem;
}
