:root {
  --vw: 1vw;
  --ion-safe-area-top: 40px;
  --ion-safe-area-bottom: 22px;
}

@media (min-width: 450px), (min-height: 900px) {
  body.desktop-wrapper {
    --vw: calc(360px / 100);
  }
  body.has-script.desktop-wrapper {
    max-width: 360px;
    max-height: 640px;
    margin: calc((100vh - 640px) / 2) calc((100vw - 360px) / 2);
    background-image: url("./assets/desktop-background.jpg");
    background-size: cover;
    background-position: center;
    --ion-safe-area-top: 0;
  }
  body.has-script.desktop-wrapper .modal-wrapper {
    max-width: 360px;
    max-height: 640px;
    left: 0;
  }
}

body.swal2-height-auto {
  height: 100% !important;
}
